import type {LinksFunction} from '@remix-run/cloudflare';

import {parseInlineMarkdown} from '@/utils/markdown';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Header from '@/components/brochureV2/section/Header/Header';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import PageLayout from '@/components/shared/Page/PageLayout';
import {ColorSchemeEnum, HeaderBackgroundColorScheme} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';
import foundationStylesheet from '@/stylesheets/foundation/foundation.css?url';

import Faq from './components/Faq/Faq';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: foundationStylesheet}];
};

export {loader} from '@/utils/server/loader/loader';

export default function Charge() {
  const {t} = useTranslations();

  return (
    <PageLayout
      header={(props) => (
        <Header {...props} background={HeaderBackgroundColorScheme.Black} />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
    >
      <Section>
        <SectionHeader
          center={true}
          className="pt-hero-top"
          headingHtml={t('terms.title')}
          subheadHtml={parseInlineMarkdown(t('terms.intro_paragraph'))}
        />
        <Faq
          accordionItems={[
            {
              id: '1',
              heading: t('terms.question1'),
              content: parseInlineMarkdown(t('terms.question1_answer')),
            },
            {
              id: '2',
              heading: t('terms.question2'),
              content: parseInlineMarkdown(t('terms.question2_answer')),
            },
            {
              id: '3',
              heading: t('terms.question3'),
              content: parseInlineMarkdown(t('terms.question3_answer')),
            },
            {
              id: '4',
              heading: t('terms.question4'),
              content: parseInlineMarkdown(t('terms.question4_answer')),
            },
            {
              id: '5',
              heading: t('terms.question5'),
              content: parseInlineMarkdown(t('terms.question5_answer')),
            },
            {
              id: '6',
              heading: t('terms.question6'),
              content: parseInlineMarkdown(t('terms.question6_answer')),
            },
            {
              id: '7',
              heading: t('terms.question7'),
              content: parseInlineMarkdown(t('terms.question7_answer')),
            },
            {
              id: '8',
              heading: t('terms.question8'),
              content: parseInlineMarkdown(t('terms.question8_answer')),
            },
            {
              id: '9',
              heading: t('terms.question9'),
              content: parseInlineMarkdown(t('terms.question9_answer')),
            },
          ]}
          center={true}
        />
      </Section>
    </PageLayout>
  );
}
